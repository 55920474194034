import React, { useCallback, useEffect, useState } from "react";
import { Action } from "redux";
import { connect } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { useMutation } from "@apollo/client/react/hooks";

import Section from "../Section";
import {
  AppState,
  ProjectId,
  ProjectIntegrations,
  ProjectIntegrationsInput,
  ProjectIntegrationsSaveResult,
  ProjectIntegrationsSectionInput,
} from "../../../../../common/types";
import { SAVE_PROJECT_INTEGRATIONS } from "./queries";
import { setProjectIntegrations } from "../../../../../actions/projectActions";
import { BasicDataSectionType, getEmptyProjectIntegrationsSectionInput } from "../../../../../common/constants";
import EditDetails from "./EditDetails";
import ViewDetails from "./ViewDetails";
import { pollForBasicDataSaveReady } from "../../queries";

const mapStateToProps = (state: AppState) => {
  return {
    projectIntegrationsInput: state.projectState.projectInput
      ? state.projectState.projectInput.projectIntegrations
      : getEmptyProjectIntegrationsSectionInput(),
  };
};
const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => {
  return {
    setProjectIntegrationsInput: (input: ProjectIntegrationsSectionInput) => dispatch(setProjectIntegrations(input)),
  };
};

interface IntegrationsSectionProps {
  projectId: ProjectId;
  sectionDetails: ProjectIntegrations;
  sectionEditable: boolean;
  editSectionType: BasicDataSectionType | undefined;
  setEditSectionType: (type: BasicDataSectionType | undefined) => void;
  refetchDetails: () => void;
}

const TYPE: BasicDataSectionType = BasicDataSectionType.Integrations;

function IntegrationsSection(
  props: IntegrationsSectionProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
) {
  const {
    projectId,
    sectionDetails,
    sectionEditable,
    editSectionType,
    setEditSectionType,
    refetchDetails,
    projectIntegrationsInput,
    setProjectIntegrationsInput,
  } = props;
  const [editMode, setEditMode] = useState(false);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  // Once input is changed, clear error messages.
  useEffect(() => {
    if (errorMessages.length > 0) {
      setErrorMessages([]);
    }
  }, [projectIntegrationsInput]);

  const [saveMutation, { loading: saving }] = useMutation<{
    saveProjectIntegrations: ProjectIntegrationsSaveResult;
  }>(SAVE_PROJECT_INTEGRATIONS);

  const { pollForReady, ready, loading: polling, error: pollingError } = pollForBasicDataSaveReady();

  useEffect(() => {
    if (ready && !polling && !pollingError) {
      setEditMode(false);
      setEditSectionType(undefined);
      setProjectIntegrationsInput(getEmptyProjectIntegrationsSectionInput());
      refetchDetails();
    } else if (!polling && pollingError) {
      setErrorMessages(errorMessages.concat([pollingError]));
    }
  }, [ready, polling, pollingError]);

  const save = useCallback(
    (input: ProjectIntegrationsInput) => {
      saveMutation({
        variables: {
          projectId: projectId,
          integrations: input,
        },
      })
        .then(({ data }) => {
          if (data && data.saveProjectIntegrations && data.saveProjectIntegrations.applicationModifiedDateTime) {
            pollForReady(projectId, data.saveProjectIntegrations.applicationModifiedDateTime);
          } else if (data && data.saveProjectIntegrations.errors) {
            setErrorMessages(errorMessages.concat(data.saveProjectIntegrations.errors));
          }
        })
        .catch(apolloError => {
          setErrorMessages(errorMessages.concat([apolloError.message]));
        });
    },
    [saveMutation]
  );

  const noInput = projectIntegrationsInput.input.sendToRadar === null;
  const disabled = editSectionType !== undefined && editSectionType !== TYPE;

  return (
    <Section
      header="Integrations"
      disabled={disabled}
      editMode={editMode}
      editable={sectionEditable}
      saving={saving || polling}
      saveEnabled={!noInput && !projectIntegrationsInput.pristine}
      errors={errorMessages.length > 0 ? errorMessages : undefined}
      onEditClicked={() => {
        setEditSectionType(TYPE);
        setEditMode(true);
      }}
      onCancelEdit={() => {
        setProjectIntegrationsInput({ input: { sendToRadar: sectionDetails.sendToRadar }, pristine: true });
        setEditSectionType(undefined);
        setEditMode(false);
      }}
      onSaveClicked={() => {
        save(projectIntegrationsInput.input);
      }}
    >
      {editMode ? (
        <EditDetails projectId={projectId} integrations={sectionDetails} />
      ) : (
        <ViewDetails projectId={projectId} integrations={sectionDetails} />
      )}
    </Section>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(IntegrationsSection);
