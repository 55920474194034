import { gql } from "@apollo/client";
import { FieldPropertiesFields } from "../queries";

export const GET_PROJECT_INTEGRATIONS_EDIT_INFORMATION = gql`
  query GetProjectIntegrationsEditInformation($projectId: ProjectId!) {
    projectDetailsEditInformation(projectId: $projectId) {
      integrations {
        sendToRadar {
          ...FieldPropertiesFields
        }
      }
    }
  }
  ${FieldPropertiesFields}
`;

export const SAVE_PROJECT_INTEGRATIONS = gql`
  mutation SaveProjectIntegrations($projectId: ProjectId!, $integrations: ProjectIntegrationsInput!) {
    saveProjectIntegrations(projectId: $projectId, integrations: $integrations) {
      result {
        isInRadar
        sendToRadar
        radarUrl
      }
      errors
      applicationModifiedDateTime
    }
  }
`;
