import React from "react";
import { ProjectId, ProjectIntegrations } from "../../../../../common/types";
import styled from "styled-components";
import { defaultGrey, settingGreen } from "../../../../../common/colors";
import { yesNo } from "../utils";

interface IntegrationsDetailsProps {
  projectId: ProjectId;
  integrations: ProjectIntegrations;
}

function ViewDetails(props: IntegrationsDetailsProps) {
  const { integrations } = props;

  const { isInRadar, radarUrl, sendToRadar } = integrations;

  return (
    <Container>
      <CodeSection>
        <InformationItem>
          <TitleItem>Send to Radar:</TitleItem>
          <DataItem>{yesNo(sendToRadar)}</DataItem>
        </InformationItem>
        {isInRadar && (
          <InformationItem>
            <TitleItem>Radar Link</TitleItem>
            <DataItem>
              {radarUrl ? (
                <Link href={radarUrl} target="_blank" rel="noreferrer">
                  Link
                </Link>
              ) : (
                "-"
              )}
            </DataItem>
          </InformationItem>
        )}
      </CodeSection>
    </Container>
  );
}

export default ViewDetails;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 0px;
  color: ${defaultGrey};
`;

const InformationItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding-right: 5px;
`;

const TitleItem = styled.div`
  font-size: 14px;
  margin-bottom: 4px;
`;

const DataItem = styled.div<{ emphasize?: boolean }>`
  font-weight: bold;
  font-size: ${({ emphasize }) => (emphasize ? "22px" : "14px")};
`;

const CodeSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const Link = styled.a`
  color: ${settingGreen};
`;
