import React, { useState } from "react";
import styled from "styled-components";
import {
  defaultGrey,
  notificationHeaderYellow,
  projectDetailsYellow,
  settingGreen,
  valmetGreyBorder,
  valmetGreyHeader,
  valmetGreyLight,
  valmetGreyREC,
} from "../../../../common/colors";
import { gql, useApolloClient, useLazyQuery } from "@apollo/client";
import { EntityTypeId, ProjectId, NotificationMessage } from "../../../../common/types";
import ErrorBox from "../../../ErrorBox/ErrorBox";
import { format } from "date-fns";
import routes from "../../../../common/routes";
import ProjectTypeIcon from "../../../ProjectTypeIcon/ProjectTypeIcon";
import { ActionButton } from "../../../../common/components";

const GET_NOTIFICATIONS = gql`
  query GetNotifications($num: Int!, $offset: Int!) {
    currentUserNotifications(num: $num, offset: $offset) {
      projectNotifications {
        projectId
        projectDescription
        projectTechnicalTypeId
        notifications {
          modified
          message
        }
      }
      total
    }
  }
`;

type ProjectNotifications = {
  projectId: ProjectId;
  projectDescription: string;
  projectTechnicalTypeId: EntityTypeId;
  notifications: NotificationMessage[];
};

type ProjectNotificationsResult = {
  projectNotifications: ProjectNotifications[];
  total: number;
};

type ProjectNotificationsQueryResult = {
  currentUserNotifications: ProjectNotificationsResult;
};

const ProjectDisplay = (props: { projectId: ProjectId; projectTechnicalType: EntityTypeId; description: string }) => {
  const { projectId, projectTechnicalType, description } = props;
  return (
    <ProjectDisplayContainer>
      <ProjectTypeIcon projectTechnicalType={projectTechnicalType} />
      <ProjectIdDisplay>{projectId}</ProjectIdDisplay> {description}
    </ProjectDisplayContainer>
  );
};

const DateDisplay = (props: { date: Date }) => {
  const { date } = props;
  return (
    <DateContainer>
      <DateValue>{format(date, "dd.MM.YYY")}</DateValue>
      <TimeValue>{format(date, "HH:mm")}</TimeValue>
    </DateContainer>
  );
};

function Notifications(): React.ReactElement {
  const pageSize = 10;
  const [page, setPage] = useState(0);
  const client = useApolloClient();

  const [fetchNotifications, { data, loading, error }] = useLazyQuery<ProjectNotificationsQueryResult>(
    GET_NOTIFICATIONS,
    {
      variables: {
        num: pageSize,
        offset: page * pageSize,
      },
    }
  );
  const notificationsResult = data && data.currentUserNotifications;
  const notificationCount = notificationsResult && notificationsResult.total;
  const pageCount = notificationCount !== undefined ? Math.ceil(notificationCount / pageSize) : 1;

  // prefetch next page
  if (page + 1 < pageCount) {
    client
      .query({
        query: GET_NOTIFICATIONS,
        variables: {
          num: pageSize,
          offset: (page + 1) * pageSize,
        },
      })
      .then();
  }

  const footer = (
    <FooterContainer>
      <ActionButton disabled={page === 0} onClick={() => setPage(page - 1)}>
        {"<"}
      </ActionButton>
      <PageDisplay>
        {page + 1} of {pageCount}
      </PageDisplay>
      <ActionButton disabled={page === pageCount - 1} onClick={() => setPage(page + 1)}>
        {">"}
      </ActionButton>
    </FooterContainer>
  );

  if (!loading && !error && data !== undefined) {
    const notificationsResult = data.currentUserNotifications;
    const notifications = notificationsResult.projectNotifications.map(
      ({ projectId, projectDescription, projectTechnicalTypeId, notifications }) => (
        <Notification key={projectId}>
          <NotificationHeader>
            <NotificationLink href={routes.project(projectId)}>
              <ProjectDisplay
                projectId={projectId}
                projectTechnicalType={projectTechnicalTypeId}
                description={projectDescription}
              />
            </NotificationLink>
          </NotificationHeader>
          {notifications.map((n, index) => (
            <NotificationMessageRow key={index}>
              <DateDisplay date={new Date(n.modified)} />
              {n.message}
            </NotificationMessageRow>
          ))}
        </Notification>
      )
    );

    const notificationCount = notificationsResult.total;
    return (
      <Container>
        <HeaderContainer>
          Notifications
          <NotificationCount>Notifications for {notificationCount} projects</NotificationCount>
        </HeaderContainer>
        <ContentContainer>{notifications}</ContentContainer>
        {footer}
      </Container>
    );
  } else if (loading) {
    return (
      <Container>
        <HeaderContainer>Notifications</HeaderContainer>
        <ContentContainer>
          <Loading>Loading...</Loading>
        </ContentContainer>
        {footer}
      </Container>
    );
  } else if (error !== undefined) {
    return (
      <Container>
        <HeaderContainer>Notifications</HeaderContainer>
        <ContentContainer>
          <ErrorBox caption="Error while loading notifications" apolloError={error} />
        </ContentContainer>
        {footer}
      </Container>
    );
  }

  return (
    <Container>
      <HeaderContainer>Notifications</HeaderContainer>
      <ContentContainer>
        <LoadButton onClick={() => fetchNotifications()}>Click to load notifications</LoadButton>
      </ContentContainer>
    </Container>
  );
}

export default Notifications;

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 675px;
  border: solid 1px ${valmetGreyBorder};
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
  -moz-page-break-inside: avoid;
  break-inside: avoid-column;
  margin-bottom: 20px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${projectDetailsYellow};
  padding: 10px;
  color: ${defaultGrey};
  text-transform: uppercase;
`;

const NotificationCount = styled.span`
  font-size: 12px;
  text-transform: none;
`;

const ContentContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 20px;
  color: ${defaultGrey};
`;

const Loading = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

const FooterContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

const PageDisplay = styled.div`
  display: flex;
  min-width: 100px;
  justify-content: center;
`;

const Notification = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  border: 1px solid ${valmetGreyBorder};
`;

const NotificationHeader = styled.div`
  display: flex;
  flex-direction: row;
  padding: 5px;
  padding-left: 10px;
  background-color: ${notificationHeaderYellow};
`;

const NotificationLink = styled.a`
  color: inherit;
  text-decoration: none;
`;

const NotificationMessageRow = styled.div`
  display: flex;
  padding: 5px;
  margin-left: 10px;
  flex-direction: row;
  align-items: center;
`;

const DateContainer = styled.div`
  margin-right: 20px;
  color: ${valmetGreyLight};
`;

const DateValue = styled.span`
  margin-right: 5px;
  font-size: 13px;
`;

const TimeValue = styled.span`
  font-size: 11px;
`;

const ProjectDisplayContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

const ProjectIdDisplay = styled.span`
  margin-right: 10px;
  min-width: 40px;
  color: ${valmetGreyHeader};
`;

const LoadButton = styled.button`
  font-size: 16px;
  padding: 16px;
  width: 300px;
  align-self: center;
  cursor: pointer;
  color: rgb(40, 40, 40);
  background: rgb(230, 218, 210);
  border: 1px solid ${valmetGreyHeader};
`;
