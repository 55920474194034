import React from "react";

import { ProjectId, ProjectIntegrations } from "../../../../../common/types";
import CreateProjectSection from "../CreateProjectSection";
import EditDetails from "./EditDetails";

interface IntegrationsCreateProjectSectionProps {
  projectId: ProjectId;
  sectionDetails: ProjectIntegrations;
}

function IntegrationsCreateProjectSection(props: IntegrationsCreateProjectSectionProps) {
  const { projectId, sectionDetails } = props;

  return (
    <CreateProjectSection header="Integrations">
      <EditDetails projectId={projectId} integrations={sectionDetails} />
    </CreateProjectSection>
  );
}

export default IntegrationsCreateProjectSection;
