import { gql } from "@apollo/client";

export const FieldPropertiesFields = gql`
  fragment FieldPropertiesFields on FieldProperties {
    editable
    nullable
    message
  }
`;

export const GET_PROJECT_EDIT_INFORMATION = gql`
  query GetProjectEditInformation($projectId: ProjectId!) {
    projectDetailsEditInformation(projectId: $projectId) {
      projectCurrency {
        contractCurrencyProperties {
          ...FieldPropertiesFields
        }
        legalEntityCurrencyProperties {
          ...FieldPropertiesFields
        }
        projectCurrencyProperties {
          ...FieldPropertiesFields
        }
        revenueRecognitionCurrencyProperties {
          ...FieldPropertiesFields
        }
        externalReportingCurrencyProperties {
          ...FieldPropertiesFields
        }
        internalReportingCurrencyProperties {
          ...FieldPropertiesFields
        }
      }
      projectDates {
        OBLPeriodProperties {
          ...FieldPropertiesFields
        }
        asSoldPeriodProperties {
          ...FieldPropertiesFields
        }
        budgetPeriodProperties {
          ...FieldPropertiesFields
        }
        recPlanStartPeriodProperties {
          ...FieldPropertiesFields
        }
        recPlanEndPeriod {
          effective
          cached
        }
        recPlanEndPeriodProperties {
          ...FieldPropertiesFields
        }
        recCompletionPeriodProperties {
          ...FieldPropertiesFields
        }
        startUpPeriodProperties {
          ...FieldPropertiesFields
        }
        projectClosingPeriodInERPProperties {
          ...FieldPropertiesFields
        }
        warrantyStartPeriodProperties {
          ...FieldPropertiesFields
        }
        warrantyEndPeriodProperties {
          ...FieldPropertiesFields
        }
        contractEffectivePeriodProperties {
          ...FieldPropertiesFields
        }
        contractExpiryPeriodProperties {
          ...FieldPropertiesFields
        }
        migrationPeriodProperties {
          ...FieldPropertiesFields
        }
        financeClosingPeriodProperties {
          ...FieldPropertiesFields
        }
      }
      projectInformation {
        organisation {
          businessGroupId {
            effective
            cached
          }
          businessGroupProperties {
            ...FieldPropertiesFields
          }
          businessTypeId {
            effective
            cached
          }
          businessTypeProperties {
            ...FieldPropertiesFields
          }
          legalEntityId {
            effective
            cached
          }
          legalEntityProperties {
            ...FieldPropertiesFields
          }
          intExtId
          intExtProperties {
            ...FieldPropertiesFields
          }
          customerId
          customerProperties {
            ...FieldPropertiesFields
          }
          industryId
          industryProperties {
            ...FieldPropertiesFields
          }
          autSiteCodeId
          autSiteCodeProperties {
            ...FieldPropertiesFields
          }
          icpTradingPartnerId
          icpTradingPartnerProperties {
            ...FieldPropertiesFields
          }
        }
        contractOwner {
          businessGroupId {
            effective
            cached
          }
          businessGroupProperties {
            ...FieldPropertiesFields
          }
          businessTypeId
          businessTypeProperties {
            ...FieldPropertiesFields
          }
          legalEntityId
          legalEntityProperties {
            ...FieldPropertiesFields
          }
        }
        EDCId
        EDCProperties {
          ...FieldPropertiesFields
        }
        autReportingCountryId
        autReportingCountryProperties {
          ...FieldPropertiesFields
        }
        deliveryOrgId
        deliveryOrgProperties {
          ...FieldPropertiesFields
        }
        deliveryLocationId
        deliveryLocationProperties {
          ...FieldPropertiesFields
        }
        financingMethodProperties {
          ...FieldPropertiesFields
        }
        performanceObligationProperties {
          ...FieldPropertiesFields
        }
        projectKeyProperties {
          ...FieldPropertiesFields
        }
        mainProjectKeyProperties {
          ...FieldPropertiesFields
        }
        projectNameProperties {
          ...FieldPropertiesFields
        }
        projectDescription {
          ... on FreetextProjectDescription {
            desc
          }
          ... on CompositeProjectDescription {
            postfix
            stem
          }
        }
        projectDescriptionProperties {
          ...FieldPropertiesFields
        }
        projectPhaseId
        projectPhaseProperties {
          ...FieldPropertiesFields
        }
        projectStatusId
        projectStatusProperties {
          ...FieldPropertiesFields
        }
        projectTechnicalTypeId
        projectTechnicalTypeProperties {
          ...FieldPropertiesFields
        }
        projectTypeId {
          effective
          cached
        }
        projectTypeProperties {
          ...FieldPropertiesFields
        }
      }
      projectRoles {
        mainProjectManagerProperties {
          ...FieldPropertiesFields
        }
        mainProjectControllerProperties {
          ...FieldPropertiesFields
        }
        projectControllerProperties {
          ...FieldPropertiesFields
        }
        projectManagerProperties {
          ...FieldPropertiesFields
        }
        projectManagersManagerProperties {
          ...FieldPropertiesFields
        }
        projectCoordinatorProperties {
          ...FieldPropertiesFields
        }
      }
      integrations {
        sendToRadar {
          ...FieldPropertiesFields
        }
      }
      reportingRelations {
        externalOrganisation {
          businessGroupId
          businessGroupProperties {
            ...FieldPropertiesFields
          }
          businessTypeId
          businessTypeProperties {
            ...FieldPropertiesFields
          }
          legalEntityId
          legalEntityProperties {
            ...FieldPropertiesFields
          }
          reportingId
          reportingProperties {
            ...FieldPropertiesFields
          }
          customerId
          customerProperties {
            ...FieldPropertiesFields
          }
          externalReportingIndustryId
          externalReportingIndustryProperties {
            ...FieldPropertiesFields
          }
          orderValueIncludedInRelated
          orderValueIncludedInRelatedProperties {
            ...FieldPropertiesFields
          }
        }
        serMarginTraceabilityProperties {
          ...FieldPropertiesFields
        }
      }
    }
  }
  ${FieldPropertiesFields}
`;

const ProjectPersonFields = gql`
  fragment ProjectPersonFields on ProjectPerson {
    userId
    firstName
    lastName
  }
`;

const ProjectTagFields = gql`
  fragment ProjectTagFields on ProjectTag {
    id
    description
  }
`;

export const GET_PROJECT_DETAILS = gql`
  query GetProjectDetails($projectId: ProjectId!) {
    projectDetails(projectId: $projectId) {
      projectDetails {
        projectId
        projectInformation {
          organisation {
            businessGroup
            businessType
            legalEntity
            intExt
            corporation
            customerId
            customerName
            industry
            autSiteCode
            icpTradingPartner
          }
          contractOwner {
            businessGroup
            businessType
            legalEntity
          }
          EDC
          autReportingCountry
          financingMethod
          performanceObligation
          projectKey
          mainProjectKey
          projectOffice
          deliveryOrg
          deliveryLocation
          name
          description
          projectPhase
          projectStatus
          projectCardStatus
          projectTechnicalType
          projectType
          projectVirtualType
        }
        projectDates {
          OBLPeriod
          asSoldPeriod
          budgetPeriod
          recPlanStartPeriod
          recPlanEndPeriod
          recCompletionPeriod
          startUpPeriod
          projectClosingPeriodInERP
          warrantyStartPeriod
          warrantyEndPeriod
          contractEffectivePeriod
          contractExpiryPeriod
          migrationPeriod
          financeClosingPeriod
        }
        projectCurrency {
          contractCurrency
          legalEntityCurrency
          projectCurrency
          revenueRecognitionCurrency
          externalReportingCurrency
          internalReportingCurrency
        }
        projectRoles {
          mainProjectManager {
            ...ProjectPersonFields
          }
          mainProjectController {
            ...ProjectPersonFields
          }
          projectController {
            ...ProjectPersonFields
          }
          projectManager {
            ...ProjectPersonFields
          }
          projectManagersManager {
            ...ProjectPersonFields
          }
          projectCoordinator {
            ...ProjectPersonFields
          }
        }
        relations {
          projectRelationId
          relationType
          company
          relatingKeys
          erpLinkStatus
        }
        projectTags {
          ...ProjectTagFields
        }
        integrations {
          isInRadar
          sendToRadar
          radarUrl
        }
        reportingRelations {
          relatingProjectId
          relatingProjectDesc
          externalOrganisation {
            businessGroup
            businessType
            legalEntity
            reporting
            customerId
            customerName
            externalReportingIndustry
            orderValueIncludedInRelated
          }
          serMarginTraceability
          relatedLegalCompany
        }
        editable {
          information
          dates
          currency
          roles
          relations
          tags
          integrations
          hierarchy
          reportingRelations
        }
      }
      projectDetailsVisibility {
        projectDates {
          OBLPeriod
          asSoldPeriod
          budgetPeriod
          recPlanStartPeriod
          recPlanEndPeriod
          recCompletionPeriod
          startUpPeriod
          projectClosingPeriodInERP
          warrantyEndPeriod
          warrantyStartPeriod
          contractEffectivePeriod
          contractExpiryPeriod
          migrationPeriod
          financeClosingPeriod
        }
        projectCurrency {
          contractCurrency
          legalEntityCurrency
          projectCurrency
          revenueRecognitionCurrency
          externalReportingCurrency
          internalReportingCurrency
        }
        projectRoles {
          mainProjectManager
          mainProjectController
          projectController
          projectManager
          projectManagersManager
          projectCoordinator
        }
        projectInformation {
          name
          description
          projectKey
          mainProjectKey
          projectPhase
          projectTechnicalType
          projectStatus
          projectCardStatus
          projectType
          EDC
          deliveryOrg
          deliveryLocation
          projectOffice
          financingMethod
          performanceObligation
          autReportingCountry
          organisation {
            businessGroup
            businessType
            legalEntity
            intExt
            corporation
            customerId
            customerName
            industry
            autSiteCode
            icpTradingPartner
          }
          contractOwner {
            businessGroup
            businessType
            legalEntity
          }
        }
        projectRelations
        projectTags
        integrations
        reportingRelations {
          relatingProjectId
          relatingProjectDesc
          externalOrganisation {
            businessGroup
            businessType
            legalEntity
            reporting
            customerId
            customerName
            externalReportingIndustry
            orderValueIncludedInRelated
          }
          serMarginTraceability
          relatedLegalCompany
        }
      }
    }
  }
  ${ProjectPersonFields}
  ${ProjectTagFields}
`;
