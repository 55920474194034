import React, { useCallback } from "react";
import {
  AppState,
  InputValue,
  ProjectId,
  ProjectIntegrations,
  ProjectIntegrationsEditInformation,
  ProjectIntegrationsSectionInput,
} from "../../../../../common/types";
import { ToggleSwitch } from "../../../../../common/components";
import styled from "styled-components";
import { defaultGrey, settingGreen, valmetGreyBorder } from "../../../../../common/colors";
import { connect } from "react-redux";
import { useQuery } from "@apollo/client/react/hooks";
import { GET_PROJECT_INTEGRATIONS_EDIT_INFORMATION } from "./queries";
import LoadingView from "../../../../LoadingView";
import { ThunkDispatch } from "redux-thunk";
import { Action } from "redux";
import { setProjectIntegrations } from "../../../../../actions/projectActions";
import { getEmptyProjectIntegrationsSectionInput } from "../../../../../common/constants";

interface EditDetailsProps {
  projectId: ProjectId;
  integrations: ProjectIntegrations;
}

const mapStateToProps = (state: AppState) => {
  return {
    projectIntegrationsInput: state.projectState.projectInput
      ? state.projectState.projectInput.projectIntegrations
      : getEmptyProjectIntegrationsSectionInput(),
    editInformation: state.projectState.projectDetailsEditInformation
      ? state.projectState.projectDetailsEditInformation.integrations
      : undefined,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, Action>) => {
  return {
    setProjectIntegrationsInput: (input: ProjectIntegrationsSectionInput) => dispatch(setProjectIntegrations(input)),
  };
};

function getOrElse<T>(i: InputValue<T>, defaultValue: T): T {
  return i !== null ? i : defaultValue;
}

const yesNo = (b: boolean) => (b ? "Yes" : "No");

function EditDetails(
  props: EditDetailsProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
) {
  const { integrations, projectId, editInformation, projectIntegrationsInput, setProjectIntegrationsInput } = props;
  const { isInRadar, radarUrl } = integrations;

  const { data, error, loading } = useQuery(GET_PROJECT_INTEGRATIONS_EDIT_INFORMATION, {
    variables: { projectId: projectId },
    skip: !projectId || editInformation !== undefined,
  });

  const editInfo = editInformation
    ? editInformation
    : !loading && !error && data
    ? data.projectDetailsEditInformation.integrations
    : undefined;

  const sendToRadar = getOrElse(projectIntegrationsInput.input.sendToRadar, integrations.sendToRadar);

  const toggleSendToRadar = useCallback(
    () => setProjectIntegrationsInput({ input: { sendToRadar: !sendToRadar }, pristine: false }),
    [projectIntegrationsInput, setProjectIntegrationsInput]
  );

  const isEditable = (fn: (e: ProjectIntegrationsEditInformation) => boolean) => editInfo && fn(editInfo);

  const sendToRadarIsEditable = isEditable(e => e.sendToRadar.editable);
  const sendToRadarTooltip = !sendToRadarIsEditable
    ? sendToRadar
      ? "Project is already sent to Radar"
      : "Project must be actual and active to enable sending project to Radar"
    : undefined;

  return (
    <Container>
      {loading ? (
        <LoadingContainer>
          <LoadingView />
        </LoadingContainer>
      ) : (
        <CodeSection>
          <InformationItem>
            <TitleItem>Send to Radar:</TitleItem>
            <Tooltip message={sendToRadarTooltip}>
              <DataItem>
                {sendToRadarIsEditable ? (
                  <ToggleSwitch checked={sendToRadar} text={yesNo(sendToRadar)} onChange={toggleSendToRadar} />
                ) : (
                  yesNo(sendToRadar)
                )}
              </DataItem>
            </Tooltip>
          </InformationItem>
          {isInRadar && (
            <InformationItem>
              <TitleItem>Radar Link</TitleItem>
              <DataItem>
                {radarUrl ? (
                  <Link href={radarUrl} target="_blank" rel="noreferrer">
                    Link
                  </Link>
                ) : (
                  "-"
                )}
              </DataItem>
            </InformationItem>
          )}
        </CodeSection>
      )}
    </Container>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(EditDetails);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  padding-bottom: 0px;
  color: ${defaultGrey};
`;

const InformationItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding-right: 5px;
`;

const TitleItem = styled.div`
  font-size: 14px;
  margin-bottom: 4px;
`;

const DataItem = styled.div<{ emphasize?: boolean }>`
  font-weight: bold;
  font-size: ${({ emphasize }) => (emphasize ? "22px" : "14px")};
`;

const CodeSection = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const Link = styled.a`
  color: ${settingGreen};
`;

const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

function Tooltip(props: React.PropsWithChildren<{ message?: string }>): React.ReactElement {
  const { children, message } = props;
  if (!message) return <>{children}</>;
  return (
    <TooltipContainer>
      {children}
      <TooltipMessage>{message}</TooltipMessage>
    </TooltipContainer>
  );
}

const TooltipContainer = styled.div`
  position: relative;

  :hover span {
    opacity: 1;
    transition: opacity 0.5s linear 0.5s;
  }
`;

const TooltipMessage = styled.span`
  opacity: 0;
  position: absolute;
  top: 25px;
  left: 0;
  background: #ffffff;
  z-index: 10;
  border-radius: 4px;
  border: 1px solid ${valmetGreyBorder};
  padding: 4px;
`;
