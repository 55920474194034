import React, { useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import routes from "../../common/routes";
import styled from "styled-components";
import PrivateRoute from "../../components/PrivateRoute";
import Main from "../../components/sites/Main";
import Projects from "../../components/sites/Projects";
import Project from "../../components/sites/Project";
import { checkLoginStatus } from "../../common/restApi";
import { AppState } from "../../common/types";
import { connect } from "react-redux";
import { Dispatch } from "redux";
import { reset } from "../../init/rootReducerAction";
import LoadingStatus from "../sites/LoadingStatus";

const mapStateToProps = (state: AppState) => {
  return {
    user: state.authState.user,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    reset: () => {
      dispatch(reset());
    },
  };
};

const RouteContainer = (
  props: RouteComponentProps & ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>
) => {
  const { history, user, reset } = props;

  useEffect(() => {
    const unListen = history.listen(() => {
      checkLoginStatus();
      reset();
    });
    return function cleanUp() {
      unListen();
    };
  });
  return (
    <Container>
      <PrivateRoute exact path={routes.MAIN} component={Main} />
      <PrivateRoute exact path={routes.PROJECTS} component={Projects} />
      <PrivateRoute path={routes.PROJECT} component={Project} />
      <PrivateRoute path={routes.NODE} component={Project} />
      {user && user.isEditor && <PrivateRoute path={routes.CREATEPROJECT} component={Project} />}
      <PrivateRoute path={routes.LOADING_STATUS} component={LoadingStatus} />
    </Container>
  );
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RouteContainer));

const Container = styled.div``;
