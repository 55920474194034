import styled from "styled-components";
import {
  cancelRed,
  commentBlue,
  defaultGrey,
  disabledGrey,
  filterGreen,
  filterGreenTransparent,
  settingGreen,
  valmetGreyBorder,
  valmetGreyREC,
} from "./colors";
import React, { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp, faEdit, faLock, faTimes } from "@fortawesome/free-solid-svg-icons";
import { useMount } from "../hooks/useMount";

export const IconButton = styled.button<{
  disabled?: boolean;
  color?: string;
  selected?: boolean;
  padding?: string;
  fontSize?: string;
  hasText?: boolean;
}>`
  background: ${({ selected }) => {
    return selected ? `${filterGreenTransparent}` : "transparent";
  }};
  outline: none;
  border: none;
  color: ${({ color }) => (color ? color : "white")};
  flex-grow: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    margin-left: ${({ hasText }) => (hasText ? "5px" : "0")};
  }
  ${({ padding }) => padding && `padding: ${padding};`}
  ${({ disabled }) => disabled && "opacity: 50%"};
  ${({ disabled }) => (disabled ? "cursor: not-allowed" : "cursor: pointer")};
  ${({ fontSize }) => fontSize && `font-size: ${fontSize};`};
`;

export const CollapseButton = styled.button<{ fontSize?: string }>`
  background-color: transparent;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0 4px;
  font-size: ${({ fontSize }) => (fontSize ? fontSize : "10px")};
`;

/* icon button within input element, e.g. clear input text */
export const InputIconButton = styled.button`
  border: 0;
  background: transparent;
  cursor: pointer;
  font-size: 12px;
  padding: 0px;
  margin-left: -12px;
  vertical-align: middle;
`;

/* clickable value in dropdown selector*/
export const ValueButton = styled.button<{ special?: boolean; childLevel?: number }>`
  border: 0;
  background: transparent;
  font-size: 12px;
  padding: 6px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: row;
  text-align: left;
  white-space: nowrap;
  font-weight: ${({ special }) => (special ? "bold" : "normal")};
  padding-left: ${({ childLevel }) => (childLevel ? `${childLevel * 10 + 6}px` : "6px")};
`;

/* not clickable information in dropdown selector*/
export const TextButton = styled.button`
  border: 0;
  background: transparent;
  font-size: 12px;
  padding: 6px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  white-space: nowrap;
  font-style: italic;
  color: ${defaultGrey};
`;

export const IconButtonLink = styled.a`
  color: white;
  flex-grow: 0;
`;

/*the first button is for continuing and the last is for canceling*/
export const ButtonContainer = styled.div<{ disabledSave?: boolean; buttonLoading?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  align-self: flex-end;
  button {
    font-size: 16px;
    font-weight: bold;
  }
  button {
    color: ${defaultGrey};
    cursor: ${({ disabledSave }) => (disabledSave ? `initial` : `pointer`)};
  }
  > button:first-child {
    color: ${({ disabledSave, buttonLoading }) =>
      disabledSave || buttonLoading ? `${disabledGrey}` : `${settingGreen}`};
  }
  > button:last-child {
    color: ${cancelRed};
    cursor: pointer;
  }
`;

export const ActionButton = styled.button<{ display?: boolean; disabled?: boolean; color?: string }>`
  display: ${({ display }) => (display !== false ? `inline-block` : `none`)};
  color: ${({ disabled, color }) => (disabled ? disabledGrey : color ? color : settingGreen)};
  border: 0;
  background: transparent;
  outline: none;
  align-items: center;
  white-space: nowrap;
  svg {
    margin-left: 5px;
    margin-right: 5px;
  }
  &:hover {
    font-weight: ${({ disabled }) => (disabled ? "normal" : "bold")};
  }

  cursor: pointer;
`;

export const TabButtonsContainer = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  button {
    margin-right: 5px;
  }
  button: last-child {
    margin-right: 0;
  }
`;

export const TabButton = styled.button<{ selected: boolean }>`
  color: ${settingGreen};
  font-size: 14px;
  font-weight: bold;
  background: ${({ selected }) => (selected ? "#ffffff" : filterGreen)};
  padding: ${({ selected }) => (selected ? "8px" : "4px")} 30px;
  cursor: pointer;
  border-color: ${settingGreen};
  border-style: solid;
  border-width: 1px 1px 0 1px;
  position: relative;
  top: ${({ selected }) => (selected ? "0px" : "8px")};
  display: flex;
  flex-direction: row;
`;

export const TabContainerX = styled.div<{ show: boolean }>`
  border: 1px solid ${settingGreen};
  display: ${({ show }) => (show ? "block" : "none")};
`;

export const TabContainer = styled.div<{ show: boolean }>`
  @media (min-width: 1250px) {
    width: 60vw;
  }
  @media (max-width: 1250px) {
    width: 90vw;
  }
  transition: width 100ms linear;
  border: 1px solid ${settingGreen};
  display: ${({ show }) => (show ? "block" : "none")};
`;

export const GroupContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GroupHeader = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 100%;
  border-bottom: 1px solid ${settingGreen};
  padding: 8px;
  cursor: pointer;
`;

export const GroupContentContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  background-color: ${valmetGreyREC};
  width: 100%;
  transition: height 100ms linear;
`;

export const GroupedItemContainer = styled.div`
  box-sizing: border-box;
  width: 80%;
  padding: 8px;
  margin: 4px;
  border: 1px solid ${settingGreen};
  box-shadow: #0002 0px 5px 10px;
  border-radius: 4px;
  background: #fff;
`;

export function UnlockIcon(props: { onClick: () => void }) {
  const { onClick } = props;
  return (
    <IconButton onClick={onClick} fontSize={"12px"}>
      <FontAwesomeIcon
        icon={faLock}
        size="1x"
        color={commentBlue}
        title="Value has been edited in POP. Click to reset back to the value from source."
      />
    </IconButton>
  );
}

type ToggleSwitchStyleProps = {
  checked: boolean | string;
  textJustify?: "left" | "right";
  color?: string;
  fontSize?: string;
  toggleSize?: "small" | "normal";
};

type ToggleSwitchProps = {
  text?: string;
  readOnly?: boolean;
  onChange: (checked: boolean) => void;
} & ToggleSwitchStyleProps;

const ToggleContainer = styled.label<{ color?: string; fontSize?: string; textJustify?: "left" | "right" }>`
  display: flex;
  ${({ textJustify }) => textJustify === "right" && "justify-content: space-between;"}
  align-items: center;
  padding-bottom: 2px;
  p {
    margin: 0;
    color: ${({ color }) => color};
    font-size: ${({ fontSize }) => fontSize};
    cursor: pointer;
  }
`;

const ToggleSwitchLabel = styled.label<{ toggleSize?: "small" | "normal"; checked: boolean | string; color?: string }>`
  position: relative;
  display: inline-block;
  width: ${({ toggleSize }) => (toggleSize === "small" ? "30px" : "40px")};
  height: ${({ toggleSize }) => (toggleSize === "small" ? "12px" : "20px")};
  margin-right: 12px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;
  }
  span:before {
    position: absolute;
    content: "";
    height: ${({ toggleSize }) => (toggleSize === "small" ? "8px" : "12px")};
    width: ${({ toggleSize, checked }) => (checked === "mixed" ? "26px" : toggleSize === "small" ? "8px" : "12px")};
    left: ${({ toggleSize }) => (toggleSize === "small" ? "2px" : "4px")};
    bottom: ${({ toggleSize }) => (toggleSize === "small" ? "2px" : "4px")};
    background-color: white;
    transition: 0.4s;
    border-radius: ${({ checked }) => (checked === "mixed" ? "34px" : "50%")};
  }
  input:checked + span {
    background-color: ${({ color }) => color};
  }
  input:focus + span {
    box-shadow: 0 0 1px ${({ color }) => color};
  }
  input:checked + span:before {
    transform: translateX(${({ toggleSize }) => (toggleSize === "small" ? "18px" : "20px")});
  }
`;

export function ToggleSwitch(props: ToggleSwitchProps): React.ReactElement {
  const { onChange, checked, readOnly, text, textJustify = "left", color = settingGreen, fontSize, toggleSize } = props;
  const styleProps: ToggleSwitchStyleProps = { checked, textJustify, color, fontSize, toggleSize };

  return (
    <ToggleContainer>
      <ToggleSwitchLabel {...styleProps}>
        <input
          type="checkbox"
          onChange={event => onChange(event.target.checked)}
          checked={checked === true}
          readOnly={readOnly}
        />
        <span />
      </ToggleSwitchLabel>
      <p>{text}</p>
    </ToggleContainer>
  );
}

export interface SwitchesDropdownValue {
  id: number | string;
  description: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
}

interface SwitchesDropdownProps {
  title: string;
  values: SwitchesDropdownValue[];
  onChangeAll: (checked: boolean) => void;
}

export function SwitchesDropdown(props: SwitchesDropdownProps): React.ReactElement {
  const { title, values, onChangeAll } = props;

  const menuRef = useRef<HTMLDivElement | null>(null);
  const [listening, setListening] = useState(false);
  function handleOutsideClicks(ev: DocumentEventMap[keyof DocumentEventMap]) {
    if (menuRef.current === null) {
      stopListener();
    } else if (menuRef.current.contains(ev.target as HTMLDivElement)) return;
    else setIsOpen(false);
  }

  function startListener() {
    if (listening) return;
    if (!menuRef.current) return;
    setListening(true);
    document.addEventListener(`click`, handleOutsideClicks, true);
  }

  function stopListener() {
    document.removeEventListener(`click`, handleOutsideClicks, true);
  }

  useMount(startListener, stopListener);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const getInitAllSelected = (): boolean | "mixed" => {
    const checks = values.map(value => value.checked);
    return checks.includes(true) && checks.includes(false) ? "mixed" : checks[0];
  };
  const [allSelected, setAllSelected] = useState<boolean | "mixed">(getInitAllSelected());

  useEffect(() => {
    setAllSelected(getInitAllSelected());
  }, [values]);

  const SwitchesDropdownContainer = styled.div`
    position: relative;
  `;
  const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 2px;
  `;
  const TitleText = styled.div`
    color: ${settingGreen};
    margin-right: 12px;
  `;

  const DropdownContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    background: white;
    border: 1px solid ${valmetGreyBorder};
    z-index: 500;
    padding: 10px;
    min-width: 240px;
    right: 0;
  `;

  const ValuesContainer = styled.div`
    display: flex;
    flex-direction: column;
    div {
      padding-bottom: 10px;
    }
  `;

  const DivideLine = styled.div`
    width: 100%;
    height: 1px;
    background-color: ${valmetGreyBorder};
    margin: 10px 0 10px;
  `;

  return (
    <SwitchesDropdownContainer ref={menuRef}>
      <TitleContainer onClick={() => setIsOpen(!isOpen)}>
        <TitleText>{title}</TitleText>
        <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} size="lg" color={settingGreen} />
      </TitleContainer>
      {isOpen && (
        <DropdownContainer>
          <ToggleSwitch
            checked={allSelected || false}
            onChange={value => {
              setAllSelected(value);
              onChangeAll(value);
            }}
            text={"Select All"}
            fontSize={"12px"}
            toggleSize={"small"}
            readOnly={allSelected === undefined}
          />
          <DivideLine />
          <ValuesContainer>
            {values.map(dropdownValue => (
              <ToggleSwitch
                key={dropdownValue.id}
                checked={dropdownValue.checked}
                onChange={checked => dropdownValue.onChange(checked)}
                text={dropdownValue.description}
                fontSize={"12px"}
                toggleSize={"small"}
              />
            ))}
          </ValuesContainer>
        </DropdownContainer>
      )}
    </SwitchesDropdownContainer>
  );
}

interface DropdownValue {
  id: string;
  name: string;
}

export interface SingleSelectionDropdownProps<T> {
  valuesList: T[];
  selectedValue: T | undefined;
  placeholder: string | undefined;
  setSelectedValue: (value: T) => void;
  color?: string;
  onEdit?: (value: T) => void;
  onDelete?: (value: T) => void;
}

export function SingleSelectionDropdown<T extends DropdownValue>(
  props: SingleSelectionDropdownProps<T>
): React.ReactElement {
  const { valuesList, selectedValue, placeholder, setSelectedValue, color, onEdit, onDelete } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const menuRef = useRef<HTMLDivElement | null>(null);
  const [listening, setListening] = useState(false);
  function handleOutsideClicks(ev: DocumentEventMap[keyof DocumentEventMap]) {
    if (menuRef.current === null) {
      stopListener();
    } else if (menuRef.current.contains(ev.target as HTMLDivElement)) return;
    else setIsOpen(false);
  }

  function startListener() {
    if (listening) return;
    if (!menuRef.current) return;
    setListening(true);
    document.addEventListener(`click`, handleOutsideClicks, true);
  }

  function stopListener() {
    document.removeEventListener(`click`, handleOutsideClicks, true);
  }

  useMount(startListener, stopListener);

  const Container = styled.div`
    cursor: pointer;
    &:hover {
      background-color: #f0f0f0;
    }
  `;

  const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    padding-bottom: 2px;
    padding-top: 2px;
  `;
  const TitleText = styled.div<{ color?: string }>`
    display: flex;
    font-style: italic;
    align-items: flex-end;
    margin-right: 22px;
    ${color && `color: ${color};`}
    min-width: 100px;
  `;

  const DropdownContainer = styled.div<{ hasContent: boolean }>`
    background: white;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
    position: absolute;
    display: flex;
    flex-direction: column;
    min-width: 180px;
    ${({ hasContent }) => {
      if (hasContent) {
        return `border: 1px solid ${valmetGreyBorder}`;
      }
    }};
    z-index: 500;
    button:hover {
      background: ${filterGreen};
    }
    margin-top: 6px;
  `;
  const DropdownItem = styled.div`
    padding: 6px;
    &:hover {
      background-color: ${filterGreen};
    }
    display: flex;
    flex-direction: row;
  `;

  return (
    <Container ref={menuRef}>
      <TitleContainer onClick={() => setIsOpen(!isOpen)}>
        <TitleText color={color}>{selectedValue ? selectedValue.name : placeholder}</TitleText>
        <CollapseButton title={"Select filter"} fontSize={"14px"}>
          <FontAwesomeIcon icon={isOpen ? faChevronUp : faChevronDown} size="lg" color={color ? color : settingGreen} />
        </CollapseButton>
      </TitleContainer>
      {isOpen && (
        <DropdownContainer hasContent={valuesList.length > 0}>
          {valuesList.map(item => (
            <DropdownItem key={item.id}>
              <ValueButton
                onClick={() => {
                  setSelectedValue(item);
                  setIsOpen(false);
                }}
              >
                {item.name}
              </ValueButton>
              {onEdit && (
                <IconButton onClick={() => onEdit(item)} title="Edit filters">
                  <FontAwesomeIcon icon={faEdit} size="1x" color={defaultGrey} />
                </IconButton>
              )}
              {onDelete && (
                <IconButton onClick={() => onDelete(item)} title="Delete filters">
                  <FontAwesomeIcon icon={faTimes} size="1x" color={"red"} />
                </IconButton>
              )}
            </DropdownItem>
          ))}
        </DropdownContainer>
      )}
    </Container>
  );
}
