import React, { useState } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";
import { faHome, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import valmetLogo from "./../../common/assets/valmet.svg";
import { defaultGrey } from "../../common/colors";
import { IconButton, IconButtonLink } from "../../common/components";
import routes from "../../common/routes";
import { AppState, User } from "../../common/types";
import CurrencySelector from "./CurrencySelector";
import BurgerMenu from "./BurgerMenu";
import Search from "./Search";
import { initNewProject } from "../../actions/projectActions";
import { Dispatch } from "redux";

interface HeaderProps {
  user?: User;
}

const mapStateToProps = (state: AppState): HeaderProps => {
  return {
    user: state.authState.user,
  };
};

const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    initNewProject: () => {
      dispatch(initNewProject());
    },
  };
};

function Header(props: HeaderProps & RouteComponentProps & ReturnType<typeof mapDispatchToProps>): React.ReactElement {
  const { user, location, history, initNewProject } = props;
  return (
    <Container>
      <TitleContainer>
        <LogoImage src={valmetLogo} />
        <a
          href={routes.MAIN}
          onClick={ev => {
            history.push(routes.MAIN);
            ev.preventDefault();
          }}
        >
          <Subtitle />
          <IconButton>
            <FontAwesomeIcon icon={faHome} size="lg" />
          </IconButton>
        </a>
      </TitleContainer>
      <SearchContainer>
        <Search />
      </SearchContainer>
      <a
        href={routes.PROJECTS}
        onClick={ev => {
          history.push(routes.PROJECTS);
          ev.preventDefault();
        }}
      >
        <ProjectLink emphasize={location.pathname === routes.PROJECTS}>Projects</ProjectLink>
      </a>
      {user && user.isEditor && (
        <ProjectLink
          emphasize={location.pathname === routes.CREATEPROJECT}
          onClick={() => {
            if (location.pathname !== routes.CREATEPROJECT) {
              initNewProject();
              history.push(routes.CREATEPROJECT);
            }
          }}
        >
          Create Project
        </ProjectLink>
      )}
      <BurgerMenu />
      <CurrencySelector />
      <UserContainer>
        <UserField>{user ? user.displayName : "Unknown"}</UserField>
        <IconButtonLink href="/auth/logout">
          <FontAwesomeIcon icon={faSignOutAlt} size="lg" />
        </IconButtonLink>
      </UserContainer>
    </Container>
  );
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  height: 75px;
  background-color: ${defaultGrey};
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  color: white;
  font-family: "Maven Pro", sans-serif;
  left: 0;
  position: sticky;
  width: 100vw;
  z-index: 1500;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 0;
  a {
    color: inherit;
    text-decoration: none;
    display: flex;
  }
`;

const Subtitle = styled.h1`
  font-size: 20px;
  margin-left: 25px;
  &::before {
    @media (max-width: 1112px) {
      content: "POP";
    }
    @media (min-width: 1113px) {
      content: "Project & Order Planning";
    }
  }
`;

const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 0;
`;

const UserField = styled.h2`
  font-size: 18px;
  margin-right: 15px;
`;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 18px;
  flex-grow: 1;
  max-width: 500px;
  margin-left: 20px;
`;

export const ProjectLink = styled.button<{ emphasize?: boolean }>`
  background-color: transparent;
  outline: none;
  border: none;
  font-size: 18px;
  margin-right: 10px;
  text-decoration: ${({ emphasize }) => (emphasize ? "underline" : "")};
  cursor: pointer;
  color: white;
  flex-grow: 0;
  padding: 10px;
`;

const LogoImage = styled.img`
  @media (max-width: 1000px) {
    width: 100px;
    height: 45px;
  }
  @media (min-width: 1001px) {
    width: 212px;
    height: 75px;
  }
`;
