import React from "react";
import styled from "styled-components";
import Notifications from "./Notifications/Notifications";
import MyProjects from "./MyProjects";

function Main(): React.ReactElement {
  return (
    <Container>
      <Content>
        <Header>
          <Title>Valmet</Title>
          <Subtitle>Project and Order Planning</Subtitle>
        </Header>
        <Columns>
          <MyProjects />
          <Notifications />
        </Columns>
      </Content>
    </Container>
  );
}

export default Main;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 20px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  /*justify-content: space-between;*/
`;

const Title = styled.h1`
  margin-right: 32px;
`;
const Subtitle = styled.h2``;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const Columns = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 15px;
`;
