import React from "react";
import styled from "styled-components";

import { defaultGrey, projectDetailsYellow, valmetGreyBorder, valmetGreyHeader } from "../../../common/colors";
import { EntityTypeId, ProjectId } from "../../../common/types";
import ProjectTypeIcon from "../../ProjectTypeIcon/ProjectTypeIcon";

export const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 675px;
  border: solid 1px ${valmetGreyBorder};
  -webkit-column-break-inside: avoid;
  -moz-column-break-inside: avoid;
  -moz-page-break-inside: avoid;
  break-inside: avoid-column;
  margin-bottom: 20px;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${projectDetailsYellow};
  padding: 0px;
  color: ${defaultGrey};
  text-transform: uppercase;
`;

export const ContentContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  padding: 20px;
  color: ${defaultGrey};
`;

export const ProjectDisplay = (props: {
  projectId: ProjectId;
  projectTechnicalType: EntityTypeId;
  description: string;
}) => {
  const { projectId, projectTechnicalType, description } = props;
  return (
    <ProjectDisplayContainer>
      <ProjectTypeIcon projectTechnicalType={projectTechnicalType} />
      <ProjectIdDisplay>{projectId}</ProjectIdDisplay> {description}
    </ProjectDisplayContainer>
  );
};

const ProjectDisplayContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

const ProjectIdDisplay = styled.span`
  margin-right: 10px;
  min-width: 40px;
  color: ${valmetGreyHeader};
`;
